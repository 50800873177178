@import "./variables";

/*********************************************************************************
  with the base font-size = calc(10px + min(0.7vmin, 10px)) and a 1440x1024 screen,
  here are the eqivalent size in px for these classes

  font:
  smallest = 12.0176px
  smaller = 13.7344px
  small = 15.4512px
  normal = 17.168px
  big = 20.6016px
  bigger = 24.0352px
  biggest = 27.4688px

  padding/margin:
  ps-05 ms-05 = 2.146px
  ps-1 ms-1 = 4.292px
  ps-15 ms-15 = 6.438px
  ps-2 ms-2 = 8.584px
  ps-25 ms-25 = 12.876px
  ps-3 ms-3 = 17.168px
  ps-35 ms-35 = 21.460px
  ps-4 ms-4 = 25.752px
  ps-45 ms-45 = 34.336px
  ps-5 ms-5 = 51.504px
  ps-55 ms-55 = 68.672px
  ps-6 ms-6 = 85.840px

  general:;
  1rem = 17.167px

**********************************************************************************/
html {
  font-size: calc(10px + min(0.7vmin, 10px));
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: $bg;
  color: $mainColor;
  font-family: "Inter", "Source Sans Pro", "Microsoft JhengHei", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

/* mainly used for overriding ant style */
* {
  font-family: inherit !important;
}

@font-face {
  font-family: customInter; /* 字体名称，你可以自定义 */
  src: url("./font/Inter-VariableFont_slnt,wght.ttf") format("truetype"); /* 字体文件路径和格式 */
}

.fontInter {
  font-family: customInter !important;
}

.big {
  @include big;
}
.bigger {
  @include bigger;
}
.biggest {
  @include biggest;
}
.normal {
  @include normal;
}
.small {
  @include small;
}
.smaller {
  @include smaller;
}
.smallest {
  @include smallest;
}

.bold {
  @include bold;
}
.light {
  @include light;
}

.inputFont {
  color: $inputColor !important;
}
.greyFont {
  color: $greyColor !important;
}
.greyerFont {
  color: $greyerColor !important;
}
.greyestFont {
  color: $greyestColor !important;
}
.whiteFont {
  color: $whiteColor !important;
}
.coolerBlueFont {
  color: $coolerBlueColor !important;
}
.greenFont {
  color: $greenColor !important;
}
.redFont {
  color: $redColor !important;
}
.importantRedFont {
  color: $importantRedColor !important;
}
.warmerRedFont {
  color: $warmerRedColor !important;
}
.yellowFont {
  color: $yellowColor !important;
}
.mainFont {
  color: $mainColor !important;
}
.mainLightFont {
  color: $mainLightColor !important;
}

.requiredS:before {
  content: "*" !important;
  color: $importantRedColor;
}
.requiredE:after {
  content: "*" !important;
  color: $importantRedColor;
}

.content-container {
  margin-top: $contentMargin;
}

.modal-content-container {
  margin: 2.1rem;
}

.editIconSize {
  background-size: 1.3rem 1.3rem !important;
  cursor: pointer;
}

.inputBox {
  padding: $inputVerticalPad $inputHorizontalPad;
  outline: none;
  border: none;
  border-radius: $smallBtnRadius;
  background-color: $whiteColor;
  color: $inputColor;
  line-height: normal;
  @include normalWeak;
  &:focus-visible {
    box-shadow: $focusShadow;
  }
  &:disabled {
    opacity: $disabledOp;
  }
  &.yellowBorder {
    border: $btnBorderWidth solid $yellowColor;
  }
  &.round {
    border-radius: 1000px;
  }
  &.dummy {
    border: $btnBorderWidth solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    background-color: transparent;
    width: 0;
    padding: $inputVerticalPad 0;
  }
}

.btn {
  color: inherit;
  position: relative;
  &:hover {
    color: inherit;
  }
  &:focus {
    box-shadow: none;
  }
  &.plain {
    &.withBorder {
      &.main {
        @include btn(transparent, $mainColor, $mainColor);
      }
      &.mainDark {
        @include btn(transparent, $mainColorDark, $mainColorDark);
      }
      &.mainDarker {
        @include btn(transparent, $mainColorDarker, $mainColorDarker);
      }
      &.yellow {
        @include btn(transparent, $yellowColor, $yellowColor);
      }
      &.white {
        @include btn(transparent, $whiteColor, $greyColor);
      }
      &.coolerBlue {
        @include btn(transparent, $coolerBlueColor, $coolerBlueColor);
      }
      &.red {
        @include btn(transparent, $redColor, $redColor);
      }
      &.importantRed {
        @include btn(transparent, $importantRedColor, $importantRedColor);
      }
      &.warmerRed {
        @include btn(transparent, $warmerRedColor, $warmerRedColor);
      }
      &.greyest {
        @include btn(transparent, $greyestColor, $greyColor);
      }
    }
    &.main {
      @include btn(transparent, $mainColor);
    }
    &.mainDark {
      @include btn(transparent, $mainColorDark);
    }
    &.mainDarker {
      @include btn(transparent, $mainColorDarker);
    }
    &.yellow {
      @include btn(transparent, $yellowColor);
    }
    &.white {
      @include btn(transparent, $whiteColor);
    }
    &.coolerBlue {
      @include btn(transparent, $coolerBlueColor);
    }
    &.red {
      @include btn(transparent, $redColor);
    }
    &.importantRed {
      @include btn(transparent, $importantRedColor);
    }
    &.warmerRed {
      @include btn(transparent, $warmerRedColor);
    }
    &.greyest {
      @include btn(transparent, $greyestColor);
    }
  }
  &.whiteBg {
    &.withBorder {
      &.main {
        @include btn($whiteColor, $mainColor, $mainColor);
      }
      &.mainDark {
        @include btn($whiteColor, $mainColorDark, $mainColorDark);
      }
      &.mainDarker {
        @include btn($whiteColor, $mainColorDarker, $mainColorDarker);
      }
      &.yellow {
        @include btn($whiteColor, $yellowColor, $yellowColor);
      }
      &.white {
        @include btn($whiteColor, $whiteColor, $greyColor);
      }
      &.coolerBlue {
        @include btn($whiteColor, $coolerBlueColor, $coolerBlueColor);
      }
      &.red {
        @include btn($whiteColor, $redColor, $redColor);
      }
      &.importantRed {
        @include btn($whiteColor, $importantRedColor, $importantRedColor);
      }
      &.warmerRed {
        @include btn($whiteColor, $warmerRedColor, $warmerRedColor);
      }
      &.greyest {
        @include btn($whiteColor, $greyestColor, $greyColor);
      }
    }
  }
  &.grey {
    @include btn($greyColor);
  }
  &.white {
    @include btn($whiteColor, $greyestColor);
    &.withBorder {
      @include btn($whiteColor, $greyestColor, $greyColor);
    }
  }
  &.main {
    @include btn($mainColor);
  }
  &.coolerBlue {
    @include btn($coolerBlueColor);
  }
  &.yellow {
    @include btn($yellowColor);
  }
  &.red {
    @include btn($redColor);
  }
  &.importantRed {
    @include btn($importantRedColor);
  }
  &.warmerRed {
    @include btn($warmerRedColor);
  }
  &.placeholder {
    @include btn(transparent, transparent);
    visibility: hidden;
    &:before {
      content: "?";
    }
  }
  &:focus-visible {
    box-shadow: $focusShadow;
  }
  &:not(.icon):active:before {
    content: "";
    position: absolute;
    display: block;
    top: -$btnBorderWidth;
    left: -$btnBorderWidth;
    right: -$btnBorderWidth;
    bottom: -$btnBorderWidth;
    background-color: $whiteLayerColor;
    border-color: $whiteLayerColor;
    border-radius: $smallBtnRadius;
    z-index: 10;
  }
  &.noBorder {
    border-width: 0 !important;
    &:active:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &.thin {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    line-height: 1;
  }

  &.round {
    border-radius: 1000px !important;

    &:active:before {
      border-radius: 1000px !important;
    }
  }
  &:disabled {
    opacity: $disabledOp;
  }
  &.cross {
    text-align: center;
    @include btn(transparent, $mainColor, $mainColor);
    &:after {
      content: "\00d7";
      display: inline-block;
      font-size: 2rem;
      color: inherit;
      line-height: 1.2rem;
      height: 1.5rem;
      width: 1.5rem;
      @include transformCenter;
    }
    &.main {
      @include btn(transparent, $mainColor, $mainColor);
    }
    &.yellow {
      @include btn(transparent, $yellowColor, $yellowColor);
    }
    &.greyer {
      @include btn(transparent, $greyerColor, $greyerColor);
    }
  }
  &.minus {
    @include btn(transparent, $redColor, $redColor);
    text-align: center;
    &:after {
      content: "-";
      display: inline-block;
      font-size: 2rem;
      color: inherit;
      line-height: 1.2rem;
      height: 1.5rem;
      width: 1.5rem;
      @include transformCenter;
    }
  }
}

.icon {
  width: auto;
  border-width: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &.smallerInfo {
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    border: 1px solid $yellowColor;
    color: $yellowColor;
    @include smallest;
    text-align: center;
    &:after {
      content: "i";
      color: inherit;
    }
  }
  &.info {
    display: inline-block;
    width: 1.1rem;
    height: 1.1rem;
    border: 2px solid $yellowColor;
    color: $yellowColor;
    @include small;
    text-align: center;
    &:after {
      content: "i";
      color: inherit;
    }
  }
  &.handle {
    display: block;
    width: 1rem;
    height: 0.8rem;
    position: relative;
    border-top: 0.1rem solid $mainColor;
    border-bottom: 0.1rem solid $mainColor;
    &:before {
      content: " ";
      display: block;
      height: 1px;
      width: 100%;
      border-top: 0.1rem solid $mainColor;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &.mirror {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  &.round {
    border-radius: 100%;
  }
  &.detail {
    @include detailBg;
  }
  &.logo {
    @include logoBg;
  }
  &.shortLogo {
    @include shortLogoBg;
  }
  &.msLogo {
    @include msLogoBg;
  }
  &.star1 {
    @include star1Bg;
  }
  &.star2 {
    @include star2Bg;
  }
  &.star3 {
    @include star3Bg;
  }
  &.star4 {
    @include star4Bg;
  }
  &.star5 {
    @include star5Bg;
  }
  &.eye {
    @include eyeBg;
  }
  &.profile {
    @include profileBg;
  }
  &.alerts {
    @include alertBg;
  }
  &.alerts.subscribe.add {
    @include subscribeAdd;
  }
  &.alerts.subscribe.remove {
    @include subscribeRemove;
  }
  &.alerts.subscribe.update {
    @include subscribeUpdate;
  }
  &.leave {
    @include leaveBg;
  }
  &.success {
    @include successYellowBg;
  }
  &.fail {
    @include sadYellowBg;
  }
  &.refresh {
    @include refreshYellowBg;
  }
  &.remove {
    @include removeYellowBg;
  }
  &.edit {
    @include editYellowBg;
  }
  &.save {
    @include saveYellowBg;
  }
  &.ascending {
    @include ascendingYellowBg;
  }
  &.descending {
    @include descendingYellowBg;
  }
  &.decrease,
  &.decrease.yellow {
    @include decreaseYellowBg;
  }
  &.increase,
  &.increase.yellow {
    @include decreaseYellowBg;
    transform: rotate(180deg);
  }
  &.warning {
    @include warningYellowBg;
  }
  &.crossThin {
    @include crossYellowBg;
  }
  &.iaq {
    @include iaqWhiteBg;
    &.yellow {
      @include iaqYellowBg;
    }
  }
  &.iaqBlue {
    @include iaqBlueBg;
  }

  &.star {
    @include starWhiteBg;
    &.yellow {
      @include starYellowBg;
    }
  }
  /*device icon*/
  &.lightOff {
    @include lightOffBg;
    &.main {
      @include lightOffBg($mainColorCode);
    }
    &.grey {
      @include lightOffBg($greyColorCode);
    }
  }
  &.lightOn,
  &.LIGHT {
    @include lightOnBg;
    &.yellow {
      @include lightOnBg($yellowColorCode);
    }
    &.main {
      @include lightOnBg($mainColorCode);
    }
    &.grey {
      @include lightOnBg($greyColorCode);
    }
  }
  &.aircon,
  &.AIRCON {
    @include airconWhiteBg;
    &.main {
      @include airconBlueBg;
    }
    &.yellow {
      @include airconYellowBg;
    }
  }
  &.button,
  &.BUTTON {
    @include buttonWhiteBg;
    &.main {
      @include buttonBlueBg;
    }
  }
  &.iaqSensor,
  &.IAQ_SENSOR {
    @include iaqSensorWhiteBg;
    &.main {
      @include iaqSensorBlueBg;
    }
  }
  &.lightSensor,
  &.LIGHT_SENSOR {
    @include lightSensorWhiteBg;
    &.main {
      @include lightSensorBlueBg;
    }
  }
  &.occupancySensor,
  &.OCCUPANCY_SENSOR {
    @include occupancySensorWhiteBg;
    &.main {
      @include occupancySensorBlueBg;
    }
  }
  &.switch,
  &.SWITCH {
    @include switchWhiteBg;
    &.main {
      @include switchBlueBg;
    }
  }
  &.door,
  &.DOOR {
    @include doorWhiteBg;
    &.main {
      @include doorBlueBg;
    }
  }
  &.fan,
  &.FAF {
    @include fanWhiteBg;
    &.main {
      @include fanBlueBg;
    }
  }
  &.energyMeter,
  &.ENERGY_METER {
    @include energyMeterWhiteBg;
    &.main {
      @include energyMeterBlueBg;
    }
  }
  &.waterMeter,
  &.WATER_METER {
    @include waterMeterWhiteBg;
    &.main {
      @include waterMeterBlueBg;
    }
  }
  &.thermometer,
  &.THERMOMETER {
    @include thermometerWhiteBg;
    &.main {
      @include thermometerBlueBg;
    }
  }
  /*device icon*/
  /*reading icon*/
  &.kwh {
    @include kwhWhiteBg;
    &.main {
      @include kwhBlueBg;
    }
  }
  &.co2 {
    @include co2WhiteBg;
    &.main {
      @include co2BlueBg;
    }
  }
  &.pm25 {
    @include pm25WhiteBg;
    &.main {
      @include pm25BlueBg;
    }
  }
  &.tvoc {
    @include tvocWhiteBg;
    &.main {
      @include tvocBlueBg;
    }
  }
  &.humidity {
    @include humidityWhiteBg;
    &.main {
      @include humidityBlueBg;
    }
  }
  &.lux {
    @include luxWhiteBg;
    &.main {
      @include luxBlueBg;
    }
  }
  &.temperature {
    @include temperatureBg;
    &.main {
      @include temperatureBg($mainColorCode);
    }
  }
  /*reading icon*/
  &.downArrow.main {
    @include downArrowBg($mainColorCode);
  }
  &.downArrow.yellow {
    @include downArrowBg($yellowColorCode);
  }
  &.upArrow.main {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    @include downArrowBg($mainColorCode);
  }
  &.upArrow.yellow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    @include downArrowBg($yellowColorCode);
  }
  &.rightArrow.main {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    @include downArrowBg($mainColorCode);
  }
  &.rightArrow.yellow {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    @include downArrowBg($yellowColorCode);
  }
  &.backArrow.main {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    @include downArrowBg($mainColorCode);
  }
  &.backArrow.yellow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    @include downArrowBg($yellowColorCode);
  }
  &.tick {
    &.white {
      @include tickBg($whiteColorCode);
    }
    &.yellow {
      @include tickBg($yellowColorCode);
    }
    &.green {
      @include tickBg($greenColorCode);
    }
  }
  &.stop {
    @include stopHandYellowBg;
  }
  &.clock {
    @include clockWhiteBg;
    &.main {
      @include clockBlueBg;
    }
  }
  &.energy {
    @include energyWhiteBg;
  }
  &.requestSummary {
    @include requestSummaryWhiteBg;
  }
  &.controlOthers {
    @include controlOthersWhiteBg;
    &.main {
      @include controlOthersBlueBg;
    }
  }
  &.locationMark {
    transform: translate(-50%, -100%);
    @include locationMarkBg;
  }

  &.hotdesk,
  &.HOTDESK {
    border-radius: 0;
    @include hotdeskWhiteBg;
    &.yellow {
      @include hotdeskYellowBg;
    }
    &.main {
      @include hotdeskBg;
    }
  }
  &.floor,
  &.FLOOR,
  &.zone,
  &.ZONE {
    border-radius: 0;
    @include floorWhiteBg;
    &.yellow {
      @include floorYellowBg;
    }
    &.main {
      @include floorBg;
    }
  }
  &.workstation,
  &.WORKSTATION,
  &.fixdesk,
  &.FIXDESK {
    border-radius: 0;
    @include workstationWhiteBg;
    &.yellow {
      @include workstationYellowBg;
    }
    &.main {
      @include workstationBg;
    }
  }
  &.room,
  &.ROOM {
    border-radius: 0;
    @include roomWhiteBg;
    &.yellow {
      @include roomYellowBg;
    }
    &.main {
      @include roomBg;
    }
  }

  &.empty {
    @include emptyBg;
  }
  &.search {
    @include searchBg;
  }

  /*  &.clearday,
  &.clearnight {
    @include sunnyBg;
  }
  &.partlycloudyday,
  &.partlycloudynight {
    @include partlycloudyBg;
  }
  &.cloudy,
  &.wind {
    @include cloudyBg;
  }
  &.rain {
    @include rainBg;
  }
  &.snow {
    @include snowBg;
  }
  &.hail {
    @include hailBg;
  }
  &.sleet {
    @include sleetBg;
  }
  &.fog {
    @include fogBg;
  }
  &.thunderstorm,
  &.tornado {
    @include thunderstormBg;
  }
  */

  /* menu */
  &.control {
    @include controlWhiteBg;
  }
  &.admin {
    @include adminWhiteBg;
  }
  &.insights {
    @include insightsWhiteBg;
  }
  &.insights.collapseOpen {
    @include insightsCollapseOpen;
  }
  &.insights.collapseClose {
    @include insightsCollapseClose;
  }
  &.dashboard {
    @include dashboardWhiteBg;
  }
  &.weather.locationLogo {
    @include locationLogo;
  }
  &.settings {
    @include settingsWhiteBg;
    &.main {
      @include settingsBlueBg;
    }
  }
  &.scene {
    @include sceneWhiteBg;
    &.main {
      @include sceneBlueBg;
    }
    &.yellow {
      @include sceneYellowBg;
    }
  }
  &.location {
    @include locationWhiteBg;
    &.main {
      @include locationBlueBg;
    }
  }
  &.log,
  &.heatmap {
    @include logWhiteBg;
    &.main {
      @include logBlueBg;
    }
  }
  &.schedule,
  &.calendar {
    @include scheduleWhiteBg;
    &.main {
      @include scheduleBlueBg;
    }
  }
  &.account {
    @include accountWhiteBg;
    &.main {
      @include accountBlueBg;
    }
  }
  /* menu */

  &.map,
  &.map.yellow {
    @include mapYellowBg;
  }
  &.list,
  &.list.yellow {
    @include listYellowBg;
  }
  &.dashboard.star {
    @include dashboardwidgetStar;
  }
  &.dashboard.cartoon1 {
    @include dashboardwidgetCartoonGrade1;
  }
  &.dashboard.cartoon2 {
    @include dashboardwidgetCartoonGrade2;
  }
  &.dashboard.cartoon3 {
    @include dashboardwidgetCartoonGrade3;
  }
  &.dashboard.cartoon4 {
    @include dashboardwidgetCartoonGrade4;
  }
  &.dashboard.cartoon5 {
    @include dashboardwidgetCartoonGrade5;
  }
  &.dashboard.headerEllipsis {
    @include headerEllipsisIcon;
  }
  &.dashboard.headerMenu {
    @include headerMenuIcon;
  }
  &.dashboard.delete {
    @include headerDeleteIcon;
  }
  &.dashboard.student {
    @include headerStudentIcon;
  }
  &.dashboard.slidehandle {
    @include headerSlideIcon;
  }
  &.dashboard.normal {
    @include headerNormalIcon;
  }
  &.dashboard.duplicate {
    @include headerDuplicateIcon;
  }
  &.dashboard.widgetEdit {
    @include dashboardWidgetEdit;
  }

  &.dashboard.widgetMove {
    @include dashboardwidgetMove;
  }

  &.dashboard.widgetExport {
    @include dashboardwidgetExport;
  }

  &.dashboard.widgetCodeWall {
    @include dashboardwidgetCodeWall;
  }
  &.dashboard.widgetCo2 {
    @include dashboardwidgetCo2;
  }
  &.dashboard.widgetConflict {
    @include dashboardwidgetConflict;
  }
  &.dashboard.widgetLightOn {
    @include dashboardwidgetlightOn;
  }
  &.dashboard.aircon {
    @include dashboardwidgetAircon;
  }

  &.dashboard.widgetWater {
    @include dashboardwidgetWater;
  }
  &.dashboard.widgetTemperature {
    @include dashboardwidgetTemperature;
  }

  &.insights.selectors {
    @include insightsSelectors;
  }
  &.insights.leftArrow {
    @include LeftArrowInsightsIcon;
  }
  &.insights.rightArrow {
    @include RightArrowInsightsIcon;
  }
  &.insights.menuExpand {
    @include insightsMenuExpand;
  }
  &.insights.share {
    @include insightsShare;
  }
  &.insights.datapoint {
    @include insightsDatapoint;
  }
  &.insights.sites {
    @include insightsSites;
  }
  &.insights.pov {
    @include insightsPov;
  }
  &.insights.menuPackup {
    @include insightsMenuPackUp;
  }

  &.insights.locations {
    @include insightsLocations;
  }
  &.insights.clearAll {
    @include insightsLocationsClearAll;
  }
  &.insights.expandIcon {
    @include insightsSelectExpandIcon;
  }
  &.insights.refresh {
    @include insightsLocationsRefresh;
  }
  &.insights.scrubber {
    @include insightsScrubber;
  }
  &.insights.scrubberActive {
    @include insightsScrubberActive;
  }
  &.insights.datatable {
    @include insightsDataTable;
  }

  &.insights.datatableSearch {
    @include insightsDataTableSearch;
  }

  &.insights.barChart {
    @include insightsTrendIconBarChart;
  }
  &.insights.barChartActive {
    @include insightsTrendIconBarChartActive;
  }
  &.insights.lineChart {
    @include insightsTrendIconLinechart;
  }
  &.insights.lineChartActive {
    @include insightsTrendIconLinechartActive;
  }
  &.insights.pieChart {
    @include insightsTrendIconPieChart;
  }
  &.insights.pieChartActive {
    @include insightsTrendIconPieChartActive;
  }
  &.insights.heatmap {
    @include insightsTrendIconHeatMapChart;
  }
  &.insights.heatmapActive {
    @include insightsTrendIconHeatMapChartActive;
  }
  &.insights.scorecard {
    @include insightsTrendIconScoreCardChart;
  }
  &.insights.scorecardActive {
    @include insightsTrendIconScoreCardChartActive;
  }
  &.insights.temperature {
    @include insightsTrendIconTemperatureChart;
  }
  &.insights.temperatureActive {
    @include insightsTrendIconTemperatureChartActive;
  }
  &.insights.IAQStacked {
    @include insightsTrendIconIAQStacked;
  }
  &.insights.IAQStackedActive {
    @include insightsTrendIconIAQStackedActive;
  }

  &.insights.breakdown {
    @include insightsTrendIconBreakdownChart;
  }
  &.insights.breakdownActive {
    @include insightsTrendIconBreakdownChartActive;
  }

  &.insights.collapseRight {
    @include insightsCollapseRightIcon;
  }
  &.insights.collapseDarkRight {
    @include insightsCollapseDarkRightIcon;
  }
  &.insights.kwhTabIcon {
    @include insightsKwhTabIcon;
  }
  &.insights.moneyTabIcon {
    @include insightsMoneyTabIcon;
  }
  &.insights.co2TabIcon {
    @include insightsCo2TabIcon;
  }
  &.insights.burgerTabIcon {
    @include insightsBurgerTabIcon;
  }
  &.insights.treeTabIcon {
    @include insightsTreeTabIcon;
  }
  &.insights.pineappleTabIcon {
    @include insightsPineappleTabIcon;
  }
  &.insights.taxiTabIcon {
    @include insightsTaxiTabIcon;
  }
  &.insights.bubbleTeaTabIcon {
    @include insightsBubbleTeaTabIcon;
  }
  &.insights.ramenTabIcon {
    @include insightsRamenTabIcon;
  }
  &.insights.footTabIcon {
    @include insightsFootTabIcon;
  }
}

.btn.icon {
  &:hover {
    background-color: $darkLayerColor;
  }

  &:active {
    background-color: $whiteLayerColor;
  }

  &:disabled {
    background-color: $whiterLayerColor !important;
    opacity: $disabledOp !important;
  }
}

.dot {
  &.red::before {
    @include dot($redColor);
  }
  &.green::before {
    @include dot($greenColor);
  }
  &.yellow::before {
    @include dot($yellowColor);
  }
  &.main::before {
    @include dot($mainColor);
  }
  &.coolerBlue::before {
    @include dot($coolerBlueColor);
  }
  &.importantRed::before {
    @include dot($importantRedColor);
  }
  &.warmerRed::before {
    @include dot($warmerRedColor);
  }
  &.grey::before,
  &::before {
    @include dot($greyColor);
  }
  &.placeholder::before {
    @include dot(transparent);
  }
}

.mainCard {
  background-color: $mainColor;
  color: $whiteColor;
  overflow: hidden;
  border-radius: $cardRadius;
  border: $btnBorderWidth solid $mainColor;
}
.mainBorderCard {
  background-color: $whiteColor;
  color: $mainColor;
  overflow: hidden;
  border-radius: $cardRadius;
  border: $btnBorderWidth solid $mainColorTransparent;
}

/* scroll bar */
::-webkit-scrollbar {
  width: $scrollBarWidth; /* 滚动条宽度 */
  height: $scrollBarWidth; /* 滚动条宽度 */
}
::-webkit-scrollbar-track {
  background-color: $greyColor; /* 滚动条轨道背景色 */
}
::-webkit-scrollbar-thumb {
  background-color: $yellowColor; /* 滚动条滑块背景色 */
}
::-webkit-scrollbar-thumb:hover {
  background-color: $yellowColor; /* 滚动条滑块悬停时背景色 */
}

/* Internet Explorer */
::-ms-scrollbar {
  width: $scrollBarWidth; /* 滚动条宽度 */
  height: $scrollBarWidth; /* 滚动条宽度 */
}
::-ms-scrollbar-track {
  background-color: $greyColor; /* 滚动条轨道背景色 */
}
::-ms-scrollbar-thumb {
  background-color: $yellowColor; /* 滚动条滑块背景色 */
}
::-ms-scrollbar-thumb:hover {
  background-color: $yellowColor; /* 滚动条滑块悬停时背景色 */
}

.noScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.noScrollbar::-webkit-scrollbar {
  display: none;
}
/* scroll bar */

.ReactModal__Overlay {
  z-index: 1000;
  background-color: $darkerLayerColor !important;
}

.ReactModal__Content {
  inset: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  height: 100%;
  width: 100%;
  box-shadow: $modalShadow;
}

/*.swiper {
  height: 100%;
  .swiper-pagination {
    line-height: 1;
    .swiper-pagination-bullet {
      opacity: 1;
      background-color: $ctrlBgColor;
      &.swiper-pagination-bullet-active {
        background-color: $ctrlPrimaryColor;
      }
    }
  }
}*/

/*.btn-group {
  > .btn-check:focus + .btn,
  > .btn-check + .btn:focus-visible {
    box-shadow: $focusShadow;
  }
  > .btn-check:disabled + .btn {
    opacity: $disabledOp;
  }
  > .btn:not(:last-child):active:before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  > .btn:not(:first-child):active:before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}*/

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:hover {
    color: inherit;
  }
  &.disabled {
    cursor: default;
    opacity: $disabledOp;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-color: $greyColor;
  background-color: $whiteColor;
  &:checked,
  &.main:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23#{$mainColorCode}'/%3e%3c/svg%3e");
  }
  &.yellow:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23#{$yellowColorCode}'/%3e%3c/svg%3e");
  }
}

.underline {
  border-bottom: $dividerWidth solid $lightDividerColor;
  &.yellowBorder {
    border-bottom-color: $yellowColor;
  }
  &.greyerBorder {
    border-bottom-color: $greyerColor;
  }
  &.greyestBorder {
    border-bottom-color: $greyestColor;
  }
}

.separatorH + .separatorH {
  border-top: $dividerWidth solid $lightDividerColor;
  &.thick {
    border-top-width: $dividerWidthThick;
  }
  &.yellowBorder {
    border-top-color: $yellowColor;
  }
  &.greyerBorder {
    border-top-color: $greyerColor;
  }
  &.greyestBorder {
    border-top-color: $greyestColor;
  }
}

.separatorV + .separatorV {
  border-left: $dividerWidth solid $lightDividerColor;
  &.thick {
    border-left-width: $dividerWidthThick;
  }
  &.yellowBorder {
    border-left-color: $yellowColor;
  }
  &.greyestBorder {
    border-left-color: $greyestColor;
  }
}

.fullBorder {
  border: $dividerWidth solid $lightDividerColor;
  &.thick {
    border-width: $dividerWidthThick;
  }
  &.yellowBorder {
    border-color: $yellowColor;
  }
  &.greyerBorder {
    border-color: $greyerColor;
  }
  &.greyestBorder {
    border-color: $greyestColor;
  }
  + .fullBorder {
    border-top-width: 0;
  }
}

.blockLayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $whiterLayerColor;
}

.capital::first-letter {
  text-transform: capitalize;
}
.capital *::first-letter {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.w16 {
  width: 16rem !important;
}
.w14 {
  width: 14rem !important;
}
.w12 {
  width: 12rem !important;
}
.w10 {
  width: 10rem !important;
}
.w8 {
  width: 8rem !important;
}
.w7 {
  width: 7rem !important;
}
.w6 {
  width: 6rem !important;
}
.w4 {
  width: 4rem !important;
}
.w3 {
  width: 3rem !important;
}
.w2 {
  width: 2rem !important;
}
.maxw50 {
  max-width: 50rem !important;
}
.minw12 {
  min-width: 12rem !important;
}
.minw10 {
  min-width: 10rem !important;
}
.minw8 {
  min-width: 8rem !important;
}
.minw6 {
  min-width: 6rem !important;
}
.minw4 {
  min-width: 4rem !important;
}
@media (min-width: 576px) {
  .w12-sm {
    width: 12rem !important;
  }
  .w10-sm {
    width: 10rem !important;
  }
  .w8-sm {
    width: 8rem !important;
  }
  .w7-sm {
    width: 7rem !important;
  }
  .w6-sm {
    width: 6rem !important;
  }
  .w4-sm {
    width: 4rem !important;
  }
  .w3-sm {
    width: 3rem !important;
  }
  .w2-sm {
    width: 2rem !important;
  }
}

.focus-w12,
.focus-w14,
.focus-w16,
.focus-w18,
.focus-w20,
.focus-sm-w12,
.focus-sm-w14,
.focus-sm-w16,
.focus-sm-w18,
.focus-sm-w20,
.focus-md-w12,
.focus-md-w14,
.focus-md-w16,
.focus-md-w18,
.focus-md-w20 {
  &:focus-within {
    transition: width 0.2s;
  }
}
.focus-w12:focus-within {
  width: 12rem !important;
}
.focus-w14:focus-within {
  width: 14rem !important;
}
.focus-w16:focus-within {
  width: 16rem !important;
}
.focus-w18:focus-within {
  width: 18rem !important;
}
.focus-w20:focus-within {
  width: 20rem !important;
}
@media (min-width: 576px) {
  .focus-sm-w12:focus-within {
    width: 12rem !important;
  }
  .focus-sm-w14:focus-within {
    width: 14rem !important;
  }
  .focus-sm-w16:focus-within {
    width: 16rem !important;
  }
  .focus-sm-w18:focus-within {
    width: 18rem !important;
  }
  .focus-sm-w20:focus-within {
    width: 20rem !important;
  }
}
@media (min-width: 768px) {
  .focus-md-w12:focus-within {
    width: 12rem !important;
  }
  .focus-md-w14:focus-within {
    width: 14rem !important;
  }
  .focus-md-w16:focus-within {
    width: 16rem !important;
  }
  .focus-md-w18:focus-within {
    width: 18rem !important;
  }
  .focus-md-w20:focus-within {
    width: 20rem !important;
  }
}

/*@media (min-width: 768px) {
  .p-md-35 {
    padding: 1.25rem !important;
  }
  .p-md-55 {
    padding: 4rem !important;
  }
  .p-md-6 {
    padding: 5rem !important;
  }
}

@media (min-width: 992px) {
  .p-lg-35 {
    padding: 1.25rem !important;
  }
  .p-lg-55 {
    padding: 4rem !important;
  }
  .p-lg-6 {
    padding: 5rem !important;
  }
}

@media (min-width: 1140px) {
  .p-xl-35 {
    padding: 1.25rem !important;
  }
  .p-xl-55 {
    padding: 4rem !important;
  }
  .p-xl-6 {
    padding: 5rem !important;
  }
  .mb-xl--5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl--6 {
    margin-bottom: -5rem !important;
  }
}*/

.page-layout-container {
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

/* ant */
/* dropdown */
.ant-select {
  font-family: inherit;
  line-height: normal;
  height: auto !important;
  width: 100%;
  color: $greyestColor;
  @include normalWeak;
  &.round {
    .ant-select-selector {
      border-radius: 1000px;
    }
  }
  &.noBorder {
    .ant-select-selector {
      border-width: 0;
    }
  }
  .ant-select-selector {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    padding: $btnPadV $btnPadH !important;
    height: auto !important;
    line-height: normal;
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: normal !important;
    }
    &:after {
      line-height: normal !important;
    }
  }
  .ant-select-selection-item {
    font-size: inherit;
    font-weight: inherit;
    padding: 0 0.75rem !important;
    text-align: left;
  }
  .ant-select-arrow {
    margin-top: 0;
    height: auto;
    transform: translateY(-50%);
  }
}
.ant-select-dropdown {
  padding: 0.6rem 0.3rem;
  .ant-select-item-option {
    padding: 0.5rem;
    color: $greyestColor;
    @include normal;
    &.ant-select-item-option-selected,
    &.ant-select-item-option-active {
      color: $greyestColor;
      font-weight: 400;
      background-color: $yellowColor;
    }
  }
}

.drop-down-container {
  padding-top: 1rem !important;
  .ant-dropdown-menu {
    border-radius: 4px;

    .ant-dropdown-menu-item {
      margin: 0.5rem 0.8rem 0.5rem 0.8rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      // padding: 2px px;
      .ant-dropdown-menu-title-content button {
        color: $yellowColor;
      }
    }
  }

  .ant-dropdown-menu .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu .ant-dropdown-menu-item-active {
    color: $whiteColor;
    background-color: $yellowColor;

    .ant-dropdown-menu-title-content button {
      color: $whiteColor;
    }
  }
}

.drop-down-size {
  .ant-dropdown-menu-item {
    padding: 0px !important;
  }
}

.ant-select.ant-tree-select {
  .ant-select-selector {
    font-size: inherit;
  }
}

.ant-tree .ant-tree-switcher-loading-icon.anticon-loading {
  color: $yellowColor;
}

/* common modal */
$modalHeight: 31rem;
.ant-modal-wrap.commonAntModal {
  &:before {
    display: none;
  }
  > .ant-modal {
    top: calc(50% - #{$modalHeight} / 2);
    width: 80% !important;
    min-width: 35rem;
    max-width: 45rem;
    font: inherit;
    color: inherit;
    .ant-modal-content {
      margin: auto;
      height: $modalHeight;
      display: flex !important;
      flex-direction: column !important;
      border-radius: $cardRadius;
      .ant-modal-header {
        border-bottom: $dividerWidth solid $dividerColor;
        padding-bottom: 0.71rem;
        margin-bottom: 0;
        .ant-modal-title {
          color: $mainColor;
          @include big;
        }
      }
      .ant-modal-body {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        display: flex !important;
        flex-direction: column !important;
        @include small;
      }
      .ant-modal-close:focus-visible {
        outline: none;
        box-shadow: $focusShadow;
      }
      .ant-modal-close-x {
        font-size: 1.5rem;
        color: $yellowColor !important;
      }
    }
  }
}
/* ant */
