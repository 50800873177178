@import "./variables";

.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}

.flex-fill-remain {
  flex-basis: 0 !important;
  flex-grow: 1 !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.p-05 {
  padding: 0.125rem !important;
}
.p-15 {
  padding: 0.375rem !important;
}
.p-25 {
  padding: 0.75rem !important;
}
.p-35 {
  padding: 1.25rem !important;
}
.p-45 {
  padding: 2rem !important;
}
.p-55 {
  padding: 4rem !important;
}
.p-6 {
  padding: 5rem !important;
}

.px-05 {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}
.px-15 {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}
.px-25 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-35 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.px-45 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.px-55 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
.px-6 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-05 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}
.py-15 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}
.py-25 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-35 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
.py-45 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.py-55 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.py-6 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-05 {
  padding-top: 0.125rem !important;
}
.pt-15 {
  padding-top: 0.375rem !important;
}
.pt-25 {
  padding-top: 0.75rem !important;
}
.pt-35 {
  padding-top: 1.25rem !important;
}
.pt-45 {
  padding-top: 2rem !important;
}
.pt-55 {
  padding-top: 4rem !important;
}
.pt-6 {
  padding-top: 5rem !important;
}

.pb-05 {
  padding-bottom: 0.125rem !important;
}
.pb-15 {
  padding-bottom: 0.375rem !important;
}
.pb-25 {
  padding-bottom: 0.75rem !important;
}
.pb-35 {
  padding-bottom: 1.25rem !important;
}
.pb-45 {
  padding-bottom: 2rem !important;
}
.pb-55 {
  padding-bottom: 4rem !important;
}
.pb-6 {
  padding-bottom: 5rem !important;
}

.ps-05 {
  padding-left: 0.125rem !important;
}
.ps-15 {
  padding-left: 0.375rem !important;
}
.ps-25 {
  padding-left: 0.75rem !important;
}
.ps-35 {
  padding-left: 1.25rem !important;
}
.ps-45 {
  padding-left: 2rem !important;
}
.ps-55 {
  padding-left: 4rem !important;
}
.ps-6 {
  padding-left: 5rem !important;
}

.pe-05 {
  padding-right: 0.125rem !important;
}
.pe-15 {
  padding-right: 0.375rem !important;
}
.pe-25 {
  padding-right: 0.75rem !important;
}
.pe-35 {
  padding-right: 1.25rem !important;
}
.pe-45 {
  padding-right: 2rem !important;
}
.pe-55 {
  padding-right: 4rem !important;
}
.pe-6 {
  padding-right: 5rem !important;
}

.m-05 {
  margin: 0.125rem !important;
}
.m-15 {
  margin: 0.375rem !important;
}
.m-25 {
  margin: 0.75rem !important;
}
.m-35 {
  margin: 1.25rem !important;
}
.m-45 {
  margin: 2rem !important;
}
.m-55 {
  margin: 4rem !important;
}
.m-6 {
  margin: 5rem !important;
}

.mx-05 {
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}
.mx-15 {
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}
.mx-25 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-35 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.mx-45 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.mx-55 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
.mx-6 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.my-05 {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}
.my-15 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}
.my-25 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-35 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.my-45 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.my-55 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.my-6 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mt-05 {
  margin-top: 0.125rem !important;
}
.mt-15 {
  margin-top: 0.375rem !important;
}
.mt-25 {
  margin-top: 0.75rem !important;
}
.mt-35 {
  margin-top: 1.25rem !important;
}
.mt-45 {
  margin-top: 2rem !important;
}
.mt-55 {
  margin-top: 4rem !important;
}
.mt-6 {
  margin-top: 5rem !important;
}

.mb-05 {
  margin-bottom: 0.125rem !important;
}
.mb-15 {
  margin-bottom: 0.375rem !important;
}
.mb-25 {
  margin-bottom: 0.75rem !important;
}
.mb-35 {
  margin-bottom: 1.25rem !important;
}
.mb-45 {
  margin-bottom: 2rem !important;
}
.mb-55 {
  margin-bottom: 4rem !important;
}
.mb-6 {
  margin-bottom: 5rem !important;
}

.ms-05 {
  margin-left: 0.125rem !important;
}
.ms-15 {
  margin-left: 0.375rem !important;
}
.ms-25 {
  margin-left: 0.75rem !important;
}
.ms-35 {
  margin-left: 1.25rem !important;
}
.ms-45 {
  margin-left: 2rem !important;
}
.ms-55 {
  margin-left: 4rem !important;
}
.ms-6 {
  margin-left: 5rem !important;
}

.me-05 {
  margin-right: 0.125rem !important;
}
.me-15 {
  margin-right: 0.375rem !important;
}
.me-25 {
  margin-right: 0.75rem !important;
}
.me-35 {
  margin-right: 1.25rem !important;
}
.me-45 {
  margin-right: 2rem !important;
}
.me-55 {
  margin-right: 4rem !important;
}
.me-6 {
  margin-right: 5rem !important;
}

.ms--05 {
  margin-left: -0.125rem !important;
}
.ms--1 {
  margin-left: -0.25rem !important;
}
.ms--15 {
  margin-left: -0.375rem !important;
}
.ms--2 {
  margin-left: -0.5rem !important;
}
.ms--25 {
  margin-left: -0.75rem !important;
}
.ms--3 {
  margin-left: -1rem !important;
}
.ms--35 {
  margin-left: -1.25rem !important;
}
.ms--4 {
  margin-left: -1.5rem !important;
}
.ms--45 {
  margin-left: -2rem !important;
}
.ms--5 {
  margin-left: -3rem !important;
}
.ms--55 {
  margin-left: -4rem !important;
}
.ms--6 {
  margin-left: -5rem !important;
}

.me--05 {
  margin-right: -0.125rem !important;
}
.me--1 {
  margin-right: -0.25rem !important;
}
.me--15 {
  margin-right: -0.375rem !important;
}
.me--2 {
  margin-right: -0.5rem !important;
}
.me--25 {
  margin-right: -0.75rem !important;
}
.me--3 {
  margin-right: -1rem !important;
}
.me--35 {
  margin-right: -1.25rem !important;
}
.me--4 {
  margin-right: -1.5rem !important;
}
.me--45 {
  margin-right: -2rem !important;
}
.me--5 {
  margin-right: -3rem !important;
}
.me--55 {
  margin-right: -4rem !important;
}
.me--6 {
  margin-right: -5rem !important;
}

.mt--05 {
  margin-top: -0.125rem !important;
}
.mt--1 {
  margin-top: -0.25rem !important;
}
.mt--15 {
  margin-top: -0.375rem !important;
}
.mt--2 {
  margin-top: -0.5rem !important;
}
.mt--25 {
  margin-top: -0.75rem !important;
}
.mt--3 {
  margin-top: -1rem !important;
}
.mt--35 {
  margin-top: -1.25rem !important;
}
.mt--4 {
  margin-top: -1.5rem !important;
}
.mt--45 {
  margin-top: -2rem !important;
}
.mt--5 {
  margin-top: -3rem !important;
}
.mt--55 {
  margin-top: -4rem !important;
}
.mt--6 {
  margin-top: -5rem !important;
}

.mb--05 {
  margin-bottom: -0.125rem !important;
}
.mb--1 {
  margin-bottom: -0.25rem !important;
}
.mb--15 {
  margin-bottom: -0.375rem !important;
}
.mb--2 {
  margin-bottom: -0.5rem !important;
}
.mb--25 {
  margin-bottom: -0.75rem !important;
}
.mb--3 {
  margin-bottom: -1rem !important;
}
.mb--35 {
  margin-bottom: -1.25rem !important;
}
.mb--4 {
  margin-bottom: -1.5rem !important;
}
.mb--45 {
  margin-bottom: -2rem !important;
}
.mb--5 {
  margin-bottom: -3rem !important;
}
.mb--55 {
  margin-bottom: -4rem !important;
}
.mb--6 {
  margin-bottom: -5rem !important;
}

@media (min-width: 576px) {
  .p-sm-25 {
    padding: 0.75rem !important;
  }
  .px-sm-25 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .py-sm-25 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
}
/*@media (min-width: 768px) {
  .p-md-35 {
    padding: 1.25rem !important;
  }
  .p-md-55 {
    padding: 4rem !important;
  }
  .p-md-6 {
    padding: 5rem !important;
  }
}

@media (min-width: 992px) {
  .p-lg-35 {
    padding: 1.25rem !important;
  }
  .p-lg-55 {
    padding: 4rem !important;
  }
  .p-lg-6 {
    padding: 5rem !important;
  }
}

@media (min-width: 1140px) {
  .p-xl-35 {
    padding: 1.25rem !important;
  }
  .p-xl-55 {
    padding: 4rem !important;
  }
  .p-xl-6 {
    padding: 5rem !important;
  }
  .mb-xl--5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl--6 {
    margin-bottom: -5rem !important;
  }
}*/

//for vertical screen
/*@media (max-aspect-ratio: 4/5) {
  .py-v-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-v-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .pb-v-3 {
    padding-bottom: 1rem !important;
  }
  .pb-v-4 {
    padding-bottom: 1.5rem !important;
  }
  .pt-v-3 {
    padding-top: 1rem !important;
  }
  .pt-v-4 {
    padding-top: 1.5rem !important;
  }
  .pt-v-5 {
    padding-top: 3rem !important;
  }
}

@media (max-aspect-ratio: 4/5) and (min-width: 768px) {
  .p-v-md-3 {
    padding: 1rem !important;
  }
  .p-v-md-4 {
    padding: 1.5rem !important;
  }
  .p-v-md-5 {
    padding: 3rem !important;
  }
}

@media (max-aspect-ratio: 4/5) and (min-width: 992px) {
  .p-v-lg-3 {
    padding: 1rem !important;
  }
  .p-v-lg-4 {
    padding: 1.5rem !important;
  }
  .p-v-lg-5 {
    padding: 3rem !important;
  }
}*/
