@import "../../variables";

.sideMenu {
  z-index: 1;
  .sideMenuContent,
  .subSideMenuContent {
    width: $sideMenuWidth;
    min-height: 100%; //must use min-height for correct bg display
    > div > a {
      display: block;
    }
    > div:hover > a,
    > div.selected > a {
      background-color: $yellowColor;
      margin-left: 0.125rem !important;
      margin-right: 0.125rem !important;
      cursor: pointer;
      border-radius: 2px;
      color: $whiteColor;
    }
    a:focus-visible {
      box-shadow: $focusShadow;
      outline: none;
    }
  }
  .sideMenuContent {
    background-color: $mainColor;
    color: $whiteColor;
  }
  .subSideMenuContent {
    background-color: $lightGreyColor;
    color: $mainColor;
    > div:hover > a,
    > div.selected > a {
      .scene {
        @include sceneWhiteBg;
      }
      .location {
        @include locationWhiteBg;
      }
      .log {
        @include logWhiteBg;
      }
      .heatmap {
        @include logWhiteBg;
      }
      .account {
        @include accountWhiteBg;
      }
      .schedule {
        @include scheduleWhiteBg;
      }
      .calendar {
        @include scheduleWhiteBg;
      }
      .settings {
        @include settingsWhiteBg;
      }
    }
  }
}
