@import "../../variables";

.floorPlan {
  position: relative;
  flex-basis: 0 !important;
  flex-grow: 1;
  .zoomContainer {
    position: relative;
    > * {
      position: absolute;
      top: 0;
      left: 0;
    }
    .darkLayer {
      background-color: $darkerLayerColor;
    }
    .icon.marker {
      cursor: pointer;
      transform: translate(-50%, -50%);
      border-radius: 1000px;
      background-size: 70%;
      background-color: $whiteColor;
      border: 1px solid $mainColor;
      padding: 1rem;
      &.mainBg {
        background-color: $mainColor;
      }
      &.yellowBg {
        background-color: $yellowColor;
        border-color: $yellowColor;
      }
      &.redBg {
        background-color: $redColor;
        border-color: $redColor;
      }
      &.disabled {
        opacity: $disabledOp;
        cursor: default;
      }
      &.enlarge {
        transition: padding 0.15s;
        padding: 1.4rem;
      }
    }
    svg polygon {
      pointer-events: visible;
      cursor: pointer;
      opacity: 0.5;
      stroke-width: 0.15rem;
      &.normal {
        fill: $normalTempColorTransparent;
        stroke: $normalTempColor;
      }
      &.noTemp {
        fill: $noTempColorTransparent;
        stroke: $noTempColor;
      }
      &.cool {
        fill: $coolerBlueColorTransparent;
        stroke: $coolerBlueColor;
      }
      &.warm {
        fill: $warmerRedColorTransparent;
        stroke: $warmerRedColor;
      }
      &:hover,
      &.selected {
        stroke-width: 0.2rem;
        opacity: 1;
      }
    }
  }
  &.disabled {
    .zoomContainer {
      .icon.marker,
      svg polygon {
        cursor: default;
      }
    }
  }
  .darkModeBtn {
    top: 1rem;
    left: 1rem;
    box-shadow: $modalShadow;
  }
}
