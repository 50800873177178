@import "../../variables";

.searchBox {
  width: 12rem;
  position: relative;
  .icon.search {
    left: 0.5rem;
    @include transformCenterY;
  }
  .inputBox {
    padding-left: 2rem !important;
    /*border: 1px solid $greyerColor;*/
    border-radius: 1000px;
    color: $greyerColor;
  }
  &.yellowBorder .inputBox {
    border: 1px solid $yellowColor;
  }
}
