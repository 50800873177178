@import "../../variables";

.mainHeader {
  background-color: $mainColor;
  color: $whiteColor;
  height: $mainHeaderHeight;
  flex-shrink: 0;
  .ant-badge .ant-badge-count {
    box-shadow: unset;
    background: $redColor;
    min-width: 1.28rem;
    height: 1.28rem;
    line-height: 1.28rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
