@import "../../variables";

.switchBtn {
  &.form-check.form-switch {
    min-height: 0;
    padding: 0;
    margin: 0;
    .form-check-input {
      margin: 0;
      border-color: $ctrlBgColor;
      border-width: 0;
      background-color: $ctrlBgColor;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      &:checked {
        background-color: $yellowColor;
        border-color: $yellowColor;
      }
    }
  }
  &.large {
    .form-check-input {
      width: 3rem;
      height: 1.5rem;
    }
  }
}
