@import "../../variables";

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 1000px;
  background-color: $whiteColor;
  height: 1px;
  cursor: pointer;
  outline: none;
  visibility: visible;
  &.main {
    background-color: $mainColor;
  }
  &.withBorder {
    height: 5px;
    border: 1px solid $greyColor;
  }
  &:disabled {
    opacity: $disabledOp;
  }
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: $yellowColor;
  width: $sliderBtnSize;
  height: $sliderBtnSize;
  border-radius: $sliderBtnSize;
}
.slider.large::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: $yellowColor;
  width: $sliderBtnSizeLarge;
  height: $sliderBtnSizeLarge;
  border-radius: $sliderBtnSizeLarge;
}
.slider:focus-visible::-webkit-slider-thumb {
  box-shadow: $focusShadow;
}
.slider::-moz-range-thumb {
  background-color: $yellowColor;
  width: $sliderBtnSize;
  height: $sliderBtnSize;
  border-radius: $sliderBtnSize;
}
.slider.large::-moz-range-thumb {
  background-color: $yellowColor;
  width: $sliderBtnSizeLarge;
  height: $sliderBtnSizeLarge;
  border-radius: $sliderBtnSizeLarge;
}
.slider:focus-visible::-moz-range-thumb {
  box-shadow: $focusShadow;
}
.slider:active::-webkit-slider-thumb {
  border: 1px solid $whiteColor;
}
.slider:active::-moz-range-thumb {
  border: 1px solid $whiteColor;
}
