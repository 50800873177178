@import "../../../variables";

.modalLayout.controlOthersModal {
  .modalMidRow {
    height: 90%;
    .modalContent {
      width: 90%;
    }
  }
  .floorPlanContainer {
    overflow: hidden;
  }
}

@media (min-width: 992px) {
  .modalLayout.controlOthersModal {
    .floorPlanActionPanel {
      width: 30rem;
      flex-shrink: 0;
    }
  }
}
