@import "../../../variables";

.modalLayout.selectWsModal {
  .modalMidRow {
    height: 90%;
    .modalContent {
      width: 90%;
    }
  }
}
