@import "../../variables";

.dropdown.react-dropdown-select {
  border-radius: $smallBtnRadius;
  background-color: $whiteColor;
  color: $mainColor;
  border-color: $greyestColor;
  min-height: 0;
  padding: $inputVerticalPad $inputHorizontalPad;
  &:hover,
  &:focus,
  &:focus-within {
    border-color: $greyestColor;
  }
  &:focus,
  &:focus-within {
    box-shadow: $focusShadow;
  }
  .react-dropdown-select-input {
    display: none;
  }
  .react-dropdown-select-content {
    line-height: normal;
  }
  .react-dropdown-select-content,
  .react-dropdown-select-content > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .react-dropdown-select-dropdown-handle {
    padding-left: 0.5rem;
    > span {
      display: block;
    }
  }
  &.round {
    border-radius: 1000px;
  }
  .react-dropdown-select-item {
    &:focus,
    &.react-dropdown-select-item-active {
      background-color: inherit;
    }
  }
  &.main {
    .react-dropdown-select-content {
      color: $mainColor;
    }
    border-color: $mainColor;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $mainColor;
    }
    .react-dropdown-select-dropdown,
    &.react-dropdown-select-item-selected {
      > *:hover:not(.disabled) {
        background-color: $yellowColor;
      }
    }
  }
  &.yellow {
    .react-dropdown-select-content {
      color: $yellowColor;
    }
    border-color: $yellowColor;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $yellowColor;
    }
    .react-dropdown-select-dropdown {
      > *:hover:not(.disabled),
      &.react-dropdown-select-item-selected {
        background-color: $yellowColor;
      }
    }
  }
  &.yellow,
  &.main {
    &.yellowBorder {
      border-color: $yellowColor;
      &:hover,
      &:focus,
      &:focus-within {
        border-color: $yellowColor;
      }
    }
    &.mainBorder {
      border-color: $mainColor;
      &:hover,
      &:focus,
      &:focus-within {
        border-color: $mainColor;
      }
    }
  }
  &.noBorder,
  &.noBorder.yellow,
  &.noBorder.main {
    border-color: $whiteColor;
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $whiteColor;
    }
  }
  .react-dropdown-select-dropdown {
    padding: 0.6rem 0.3rem;
    > * {
      font-weight: 400;
      padding: 0.3rem;
      border-radius: $smallBtnRadius;
      border-bottom: none;
      &.disabled {
        opacity: $disabledOp;
      }
      &:hover:not(.disabled),
      &.react-dropdown-select-item-selected {
        cursor: pointer;
        background-color: $yellowColor;
        color: $whiteColor !important;
      }
    }
  }
  &[disabled] {
    opacity: $disabledOp;
  }
}

.dropdownContainer {
  position: relative;
  > .delBtnContainer {
    position: absolute;
    right: $inputHorizontalPad;
    top: 0;
    bottom: 0;
  }
  .react-dropdown-select-dropdown-handle {
    padding-right: 1.5rem; //icon width p-2 = 1rem, +0.5rem margin
  }
}

.antDropdown.ant-select {
  color: inherit;
  font: inherit;
  line-height: normal;
  height: auto !important;
  .ant-select-selector {
    color: inherit;
    font: inherit;
    line-height: normal;
    height: auto !important;
    padding: $btnPadV $btnPadH !important;
    padding-right: calc(#{$btnPadH} * 2 + 1rem) !important;
    border-radius: $smallBtnRadius;
    border-color: $yellowColor;
    .ant-select-selection-overflow {
      color: inherit;
      font: inherit;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .ant-select-selection-overflow-item {
        color: inherit;
        font: inherit;
        + .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix)::before {
          content: ", ";
        }
      }
      .ant-select-selection-overflow-item-suffix {
        display: none;
      }
      + .ant-select-selection-placeholder {
        transform: translateX(-100%);
      }
    }
    .ant-select-selection-item {
      color: inherit;
      font: inherit;
      text-align: left;
      line-height: normal !important;
      padding: 0 !important;
    }
    .ant-select-selection-placeholder {
      position: relative;
      transform: none;
      inset-inline-start: 0;
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: normal !important;
    }
    &:after {
      content: "";
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: $focusShadow !important;
      border-color: $yellowColor !important;
    }
  }
  &.ant-select-disabled {
    .ant-select-selector {
      cursor: default;
      background-color: inherit;
      color: inherit;
      opacity: $disabledOp;
    }
    .ant-select-selection-search-input {
      cursor: default !important;
    }
  }
  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border-color: $yellowColor !important;
    }
  }
  .ant-select-arrow {
    right: $btnPadH;
  }
  &.singleTag {
    .ant-select-selection-overflow-item + .ant-select-selection-overflow-item {
      display: none;
    }
  }
}
.antDropdownPopup.ant-select-dropdown {
  border-radius: $smallCardRadius;
  padding: 0.6rem 0.3rem;
  .ant-select-item-option {
    padding: 0.35rem;
    color: $mainColor;
    @include normal;
    line-height: normal !important;
    min-height: 0 !important;
    &.ant-select-item-option-selected,
    &.ant-select-item-option-active {
      color: $whiteColor;
      font-weight: 400;
      background-color: $yellowColor;
    }
  }
  .ant-select-item-group {
    @include smaller;
    color: $greyColor;
    font-weight: inherit;
    padding: 0;
    min-height: 0;
    border-bottom: 1px solid $lightDividerColor;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    border-radius: 0;
  }
  .ant-select-item-option-state {
    display: none;
  }
  .rc-virtual-list-scrollbar {
    width: $scrollBarWidth !important;
    right: -0.3rem !important;
    background-color: $greyColor !important;
    .rc-virtual-list-scrollbar-thumb {
      background-color: $yellowColor !important;
    }
  }
}
