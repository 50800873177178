@import "../../../variables";

.flexFill {
  overflow: hidden;
}
.dashboard-portal-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #e2e8f0;
  .icon.refresh {
    width: 1.85rem;
    height: 1.85rem;
    cursor: pointer;
  }
  .page-container-header {
    display: flex;
    justify-content: space-between;
    margin: 1.42rem 2rem 0;
    align-items: center;
    .header-right-option {
      display: flex;
      align-items: center;
      .ant-form-item {
        margin-bottom: 0px !important;
        label {
          color: $mainColor;
          font-size: 1.07rem;
          font-family: Inter;
        }
        .ant-select {
          min-width: 11rem;
          height: 2.14rem;
        }
        .ant-select-selector {
          padding: $btnPadV $btnPadH !important;
          border-color: $yellowColor;
          color: $mainColor;
          font-size: 1.07rem;
          font-weight: 400;
          .ant-select-selection-placeholder {
            color: $mainColor;
          }
        }
        .ant-picker {
          width: 14.28rem;
        }
      }
      .ant-form-item-period-active {
        .ant-form-item-control-input-content {
          display: flex;
          align-content: center;
          .ant-select-selector {
            border-radius: 5px 0px 0 5px;
          }
          .clear-box {
            display: flex;
            align-items: center;
            border: 1px solid $yellowColor;
            border-left: none;
            background: $whiteColor;
            padding: 0 4px;
            border-radius: 0px 5px 5px 0px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .react-grid-item {
    background-color: $whiteColor;
    border: 1px solid $mainLightColor;
    padding: 1.71rem 1.71rem;
    border-radius: 0.35rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .dashboard-widget-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .dashboard-widget-wrapper {
      background: white;
    }

    .dashboard-widget-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      background-color: $whiteColor;
      color: $mainColor;
      position: relative;
      .header-title-item {
        display: flex;
        justify-content: start;
        align-items: center;
        color: $whiteColor;
        .icon.editIconSize {
          background-size: 1.07rem 1.07rem !important;
        }
        .icon.widgetMove {
          cursor: move;
        }
        .react-resizable-handle-se {
          position: relative !important;
          right: 2px;
          bottom: 2px;
          z-index: 0;
        }
        .heading-wrapper {
          display: flex;
          width: 70%;
          flex-direction: column;
          margin-left: 0.71rem;
          color: $greyColor;
          .mainHeading {
            max-width: 100%;
            overflow: hidden;
            display: block;
            font-weight: 600;
            color: $mainColor;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .subHeading {
            color: $mainLightColor;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .sub-box {
              display: flex;
              align-items: center;
              .title {
                display: inline-block;
                max-width: 65%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
      .header-title-item:first-child {
        flex: 2.5;
      }
      .header-title-item:last-child {
        position: absolute;
        right: 0;
        z-index: 9999;
      }
      .header-title-item:last-child {
        flex: 1;
        visibility: hidden;
      }
      .icon.dashboard {
        width: 1.78rem;
        height: 1.78rem;
      }
    }

    .dashboard-widget-header-icon {
      margin: 4px;
      opacity: 0.7;
    }
    .react-resizable-handle::after {
      content: "";
      position: absolute;
      right: 5px;
      bottom: 5px;
      width: 1.42rem;
      height: 1.42rem;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI1SURBVHgBtZRJaxRBGIbfmsxETVRwwR8gKoKoKIIL4gLiwYMYD2FwJBfBgKiIDogLKqJ4iBeJCypenDGKCJksgnjRg8GTiCh6MvkDSUgCWSaZTOWp6k4f09BkPujub6/3W7qkRSajhGQ7tFZW12C383zVkNrMRZVTSkpWL1XVLy1XE7BWaI1PnpzsG1DN891qQP7s+OQIpbItaqvnRnSc93/HppWUZnWJ6KckzVByP3JetaDYKdvnalCjTsCOMYQvpkXjka2oPXzWo/+J/p/TLdhD+17LmF+JYzfhuU91KnmdsxV0Hn2eZzWlvyD50diEqugIp/8wOd0xp3Qdvk9lnVQQ2cTu5bA9xq8FTWt8whnwSdORXKd6EI2G0qpIb7AY7xsz5SnKbVQX5TwgoIxmFyjvh0mKLHM3+9eDlEW+EahjyL4mZUqHYVcyjpJp1URkK2gnti2wfZTer1qQCVGs46SHcivgOlfVufk1oNwreOX4d4cRu8xptXt9hzbj144tg22U+DMMbjAYSkq3eX/EeT9dvUCLH/mgd5RkWIch7WUcx/A7aN9qdwjmGc9lSj2EzweSXg1SBTg3clqvZ7P64091NEt/rHrdtWSambblQqhoQ9BApQHw2/NueFY7HJsOjd/hbtlXuqel7FeVv8JRRt9oQIFJfiJ5RcE0z4YIB2hVlm8PsTf9YRHCer8KY1qiTgK2uX54tM0awNkt9BPa0EbQXVD9DdHn0R3AvxP9pLtgVQuaA3NBqS5z+4rXAAAAAElFTkSuQmCC");
      border: none !important;
      background-repeat: no-repeat;
      background-size: 100%;
      display: none;
    }
    .react-resizable-handle {
      width: 0;
      height: 0;
    }
    .greyColor {
      background-color: #d9d9d9;
    }
    .flexMargin {
      margin-right: 1rem;
    }
    .chart-container {
      display: flex;
      margin: 0;
      position: relative;
    }
    .chart-container.heatmap {
      z-index: 8;
    }
  }
  .react-grid-item.grid-differ-padding {
    padding: 1.42rem 1.07rem 2.35rem 1.07rem;
  }
  .react-grid-item > .react-resizable-handle {
    z-index: 0;
  }
  .weather-container-loading {
    height: 100%;
    border: 1px solid $mainLightColor !important;
    border-radius: 0.35rem;
  }
  .dashboard-widget-wrapper.dashboard-widget-weather {
    padding: 0px !important;
    border-color: transparent;
    .icon.weather-container {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: top left;
      background-repeat: no-repeat;
      border: 1px solid $mainLightColor !important;
      border-radius: 0.35rem;
    }
    .header-title-item:last-child {
      flex: 1;
      padding-right: 1.71rem;
      padding-top: 0.85rem;
    }
    .icon.weather-container.clearnight,
    .icon.weather-container.partlycloudynight,
    .unknown {
      @include cloudyNightBg;
    }
    .icon.weather-container.clearday {
      @include sunnyBg;
    }
    .icon.weather-container.partlycloudyday,
    .icon.weather-container.cloudy {
      @include cloudyBg;
    }
    .icon.weather-container.rain,
    .icon.weather-container.thunderstorm,
    .tornado,
    .fog,
    .wind {
      @include rainBg;
    }
    .icon.weather-container.snow,
    .icon.weather-container.sleet,
    .icon.weather-container.hail {
      @include snowBg;
    }
    .weather-container {
      padding: 1.71rem 1.71rem;
      .box {
        z-index: 9999;
        .temperature {
          font-size: 3.2rem;
        }
      }
      .icon.locationLogo {
        width: 1.71rem;
        height: 1.71rem;
      }
    }
  }
  .dashboard-widget-wrapper.dashboard-widget-heatmap {
    .dashboard-widget-header {
      .mainHeading {
        width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .header-title-item:last-child {
        top: -9px;
      }
      .subHeading {
        width: 75%;
      }
    }
  }

  .react-grid-item:hover {
    .header-title-item:last-child {
      visibility: visible;
      position: absolute;
      right: -2px;
    }
    .react-resizable-handle::after {
      display: block;
    }
  }
  .ant-empty-image,
  .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-grid-placeholder {
    display: block !important;
  }
  .react-resizable-hide {
    background: $mainColor !important;
  }
  .ant-select-arrow {
    color: $yellowColor;
    font-size: 1.07rem;
  }
}
.insightPage-pop-container {
  .ant-popconfirm-buttons {
    button span {
      font-size: 0.92rem;
    }
  }
}

.dashboard-portal-container {
  .ant-select-dropdown {
    border-radius: 2px;
    margin-top: 2px;
    .ant-select-item-option {
      min-height: 1.42rem;
      line-height: 1.3rem !important;
      padding: 0.35rem !important;
    }
    .ant-select-item-option-content {
      color: $mainColor;
      font-size: 1rem;
    }
  }
  .ant-select-dropdown .ant-select-item-option.ant-select-item-option-selected,
  .ant-select-dropdown .ant-select-item-option.ant-select-item-option-active {
    border-radius: 2px;
    .ant-select-item-option-content {
      color: $whiteColor;
    }
  }
  .ant-select-dropdown .ant-select-item-group {
    font-weight: bold;
    color: $mainColor;
    font-size: 1rem;
    padding: 4px 5px;
  }
  sup {
    top: -0.4em;
  }
}
