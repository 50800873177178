@import "../../../variables";

.loginPage {
  @include loginBg;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  .eye.btn {
    right: 1rem;
    @include transformCenterY;
    &.on {
      opacity: $disabledOp;
    }
  }
  .loginCard {
    background-color: $mainColorDark;
    border-radius: $smallCardRadius;
    width: $loginCardWidth;
  }
}
