@import "../../variables";

.spinnerContainer {
  text-align: center;
}
.spinner-border {
  color: $yellowColor;
  &.thin {
    border-width: 0.15rem;
  }
}
